body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-input-search {
  margin-top: 0px !important;
  margin-bottom: 10px !important;

  @media screen and (max-width: 767px) {
    margin-top: 10px !important;
  }
}

.ant-input-group-addon > .ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-card {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.ant-card-body {
  padding: 12px !important;
}

.ant-select-selector {
  background-color: #2c947c !important;
}

.ant-select-selection-item {
  color: #fff !important;
}

.ant-select-item-option-active {
  color: #fff !important;
  background-color: #2c947c !important;
}
.ant-select-arrow {
  color: #fff !important;
}
