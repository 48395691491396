@import "~bootstrap/scss/functions";

$theme-colors: (
        "primary": #343A41,
        "info": #3c7cbe,
        "danger": #EE3E4F,
        "success": #19C689
);


@import "~bootstrap/scss/variables";
